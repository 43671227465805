/* eslint-env browser */

const DEFAULT_CONFIG = {
  objectType: undefined
};

function recognizedObjectType(type) {
  return type === 'Article' ||
    type === 'Page' ||
    type === 'Frontpage';
}

export default class PopulateDefaultValuesPlugin {
  constructor(tracker, config = {}) {
    this.tracker = tracker;
    this.config = Object.assign({}, DEFAULT_CONFIG, config);
    this.updateTrackerState();
  }

  updateTrackerState() {
    const defaultValuesPromise = this.tracker.evaluateEventInputs();
    this.tracker.update({
      provider: defaultValuesPromise.then(event => (
        recognizedObjectType(this.config.objectType || event.object.type) ? {
          'spt:engage': event.provider.id
        } : {})),
      object: defaultValuesPromise.then(({
        object,
        device,
        provider,
        origin
      } = {}) => ({
        custom: recognizedObjectType(this.config.objectType || object.type) ? {
          'spt:device': device.deviceType,
          'spt:permalink': object.url,
          'spt:referrer': origin.url,
          'spt:site': provider.id,
          'spt:url': object.url,
          'spt:articleId': object.type === 'Article' ? object.id : undefined,
          'spt:pageId': (
            object.type === 'Page'
              || object.type === 'Frontpage'
          ) ? object.id : undefined
        } : {}
      }))
    }, true);
  }
}
